<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column">
        <p class="title">Customize Slider</p>
      </div>
    </div>
    <hr />
    <div class="columns">
      <div class="column">
        <form @submit.prevent="submitForm" id="editorForm">
          <div class="box">
            <div class="columns">
              <div class="column">
                <div v-if="!updatecover1">
                  <div>
                    <div class="columns mt-1">
                      <div class="column is-narrow pt-1">
                        <div class="bold">Cover Photo</div>
                      </div>
                      <div class="column has-text-right pt-1">
                        <a @click="updateResource(1, 1)">
                          <b-tag type="is-primary is-light">
                            <i class="mdi mdi-pencil pr-1"></i> Update Photo
                          </b-tag>
                        </a>
                      </div>
                    </div>
                    <img :src="formData.cover1" style="height: 100px" />
                  </div>
                </div>
                <div v-if="updatecover1">
                  <b-field label="Cover Photo">
                    <section>
                      <b-field>
                        <b-upload v-model="cover1File" multiple drag-drop>
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                <b-icon icon="upload" size="is-large"> </b-icon>
                              </p>
                              <p>Drop your files here or click to upload</p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>

                      <div class="tags">
                        <span
                          v-for="(file, index) in cover1File"
                          :key="index"
                          class="tag is-primary"
                        >
                          {{ file.name }}
                          <button
                            class="delete is-small"
                            type="button"
                            @click="deleteDropFile(index, 1)"
                          ></button>
                        </span>
                      </div>
                    </section>
                  </b-field>
                </div>
              </div>
              <div class="column">
                <b-field label="Title*">
                  <b-input v-model="formData.title1" required></b-input>
                </b-field>
                <b-field label="Subtitle*">
                  <b-input v-model="formData.subtitle1" required></b-input>
                </b-field>
                <b-field label="Weblink">
                  <b-input v-model="formData.weblink1"></b-input>
                </b-field>
              </div>
            </div>
          </div>

          <div class="box">
            <div class="columns">
              <div class="column">
                <div v-if="!updatecover2">
                  <div>
                    <div class="columns mt-1">
                      <div class="column is-narrow pt-1">
                        <div class="bold">Cover Photo</div>
                      </div>
                      <div class="column has-text-right pt-1">
                        <a @click="updateResource(2, 1)">
                          <b-tag type="is-primary is-light">
                            <i class="mdi mdi-pencil pr-1"></i> Update Photo
                          </b-tag>
                        </a>
                      </div>
                    </div>
                    <img :src="formData.cover2" style="height: 100px" />
                  </div>
                </div>
                <div v-if="updatecover2">
                  <b-field label="Cover Photo">
                    <section>
                      <b-field>
                        <b-upload v-model="cover2File" multiple drag-drop>
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                <b-icon icon="upload" size="is-large"> </b-icon>
                              </p>
                              <p>Drop your files here or click to upload</p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>

                      <div class="tags">
                        <span
                          v-for="(file, index) in cover2File"
                          :key="index"
                          class="tag is-primary"
                        >
                          {{ file.name }}
                          <button
                            class="delete is-small"
                            type="button"
                            @click="deleteDropFile(index, 2)"
                          ></button>
                        </span>
                      </div>
                    </section>
                  </b-field>
                </div>
              </div>
              <div class="column">
                <b-field label="Title*">
                  <b-input v-model="formData.title2" required></b-input>
                </b-field>
                <b-field label="Subtitle*">
                  <b-input v-model="formData.subtitle2" required></b-input>
                </b-field>
                <b-field label="Weblink">
                  <b-input v-model="formData.weblink2"></b-input>
                </b-field>
              </div>
            </div>
          </div>

          <div class="box">
            <div class="columns">
              <div class="column">
                <div v-if="!updatecover3">
                  <div>
                    <div class="columns mt-1">
                      <div class="column is-narrow pt-1">
                        <div class="bold">Cover Photo</div>
                      </div>
                      <div class="column has-text-right pt-1">
                        <a @click="updateResource(3, 1)">
                          <b-tag type="is-primary is-light">
                            <i class="mdi mdi-pencil pr-1"></i> Update Photo
                          </b-tag>
                        </a>
                      </div>
                    </div>
                    <img :src="formData.cover3" style="height: 100px" />
                  </div>
                </div>
                <div v-if="updatecover3">
                  <b-field label="Cover Photo">
                    <section>
                      <b-field>
                        <b-upload v-model="cover3File" multiple drag-drop>
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                <b-icon icon="upload" size="is-large"> </b-icon>
                              </p>
                              <p>Drop your files here or click to upload</p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>

                      <div class="tags">
                        <span
                          v-for="(file, index) in cover3File"
                          :key="index"
                          class="tag is-primary"
                        >
                          {{ file.name }}
                          <button
                            class="delete is-small"
                            type="button"
                            @click="deleteDropFile(index, 3)"
                          ></button>
                        </span>
                      </div>
                    </section>
                  </b-field>
                </div>
              </div>
              <div class="column">
                <b-field label="Title*">
                  <b-input v-model="formData.title3" required></b-input>
                </b-field>
                <b-field label="Subtitle*">
                  <b-input v-model="formData.subtitle3" required></b-input>
                </b-field>
                <b-field label="Weblink">
                  <b-input v-model="formData.weblink3"></b-input>
                </b-field>
              </div>
            </div>
          </div>

          <div class="box">
            <div class="columns">
              <div class="column">
                <div v-if="!updatecover4">
                  <div>
                    <div class="columns mt-1">
                      <div class="column is-narrow pt-1">
                        <div class="bold">Cover Photo</div>
                      </div>
                      <div class="column has-text-right pt-1">
                        <a @click="updateResource(4, 1)">
                          <b-tag type="is-primary is-light">
                            <i class="mdi mdi-pencil pr-1"></i> Update Photo
                          </b-tag>
                        </a>
                      </div>
                    </div>
                    <img :src="formData.cover4" style="height: 100px" />
                  </div>
                </div>
                <div v-if="updatecover4">
                  <b-field label="Cover Photo">
                    <section>
                      <b-field>
                        <b-upload v-model="cover4File" multiple drag-drop>
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                <b-icon icon="upload" size="is-large"> </b-icon>
                              </p>
                              <p>Drop your files here or click to upload</p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>

                      <div class="tags">
                        <span
                          v-for="(file, index) in cover4File"
                          :key="index"
                          class="tag is-primary"
                        >
                          {{ file.name }}
                          <button
                            class="delete is-small"
                            type="button"
                            @click="deleteDropFile(index, 4)"
                          ></button>
                        </span>
                      </div>
                    </section>
                  </b-field>
                </div>
              </div>
              <div class="column">
                <b-field label="Title*">
                  <b-input v-model="formData.title4" required></b-input>
                </b-field>
                <b-field label="Subtitle*">
                  <b-input v-model="formData.subtitle4" required></b-input>
                </b-field>
                <b-field label="Weblink">
                  <b-input v-model="formData.weblink4"></b-input>
                </b-field>
              </div>
            </div>
          </div>

          <div class="box">
            <div class="columns">
              <div class="column">
                <div v-if="!updatecover5">
                  <div>
                    <div class="columns mt-1">
                      <div class="column is-narrow pt-1">
                        <div class="bold">Cover Photo</div>
                      </div>
                      <div class="column has-text-right pt-1">
                        <a @click="updateResource(5, 1)">
                          <b-tag type="is-primary is-light">
                            <i class="mdi mdi-pencil pr-1"></i> Update Photo
                          </b-tag>
                        </a>
                      </div>
                    </div>
                    <img :src="formData.cover5" style="height: 100px" />
                  </div>
                </div>
                <div v-if="updatecover5">
                  <b-field label="Cover Photo">
                    <section>
                      <b-field>
                        <b-upload v-model="cover5File" multiple drag-drop>
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                <b-icon icon="upload" size="is-large"> </b-icon>
                              </p>
                              <p>Drop your files here or click to upload</p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>

                      <div class="tags">
                        <span
                          v-for="(file, index) in cover5File"
                          :key="index"
                          class="tag is-primary"
                        >
                          {{ file.name }}
                          <button
                            class="delete is-small"
                            type="button"
                            @click="deleteDropFile(index, 5)"
                          ></button>
                        </span>
                      </div>
                    </section>
                  </b-field>
                </div>
              </div>
              <div class="column">
                <b-field label="Title*">
                  <b-input v-model="formData.title5" required></b-input>
                </b-field>
                <b-field label="Subtitle*">
                  <b-input v-model="formData.subtitle5" required></b-input>
                </b-field>
                <b-field label="Weblink">
                  <b-input v-model="formData.weblink5"></b-input>
                </b-field>
              </div>
            </div>
          </div>
          <hr />
          <div class="has-text-right">
            <!-- <b-button @click="cancel" class="button">Cancel</b-button> -->
            <button
              form="editorForm"
              class="button is-primary ml-4"
              type="submit"
              :disabled="isSubmitting"
            >
              {{ isSubmitting ? "Saving" : "Save" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
export default {
  name: "Editor",
  data() {
    return {
      isSubmitting: false,
      sliderItems: undefined,

      formData: {
        cover1: undefined,
        title1: undefined,
        subtitle1: undefined,
        weblink1: undefined,
        cover2: undefined,
        title2: undefined,
        subtitle2: undefined,
        weblink2: undefined,
        cover3: undefined,
        title3: undefined,
        subtitle3: undefined,
        weblink3: undefined,
        cover4: undefined,
        title4: undefined,
        subtitle4: undefined,
        weblink4: undefined,
        cover5: undefined,
        title5: undefined,
        subtitle5: undefined,
        weblink5: undefined
      },

      updatecover1: undefined,
      updatecover2: undefined,
      updatecover3: undefined,
      updatecover4: undefined,
      updatecover5: undefined,

      cover1File: [],
      cover2File: [],
      cover3File: [],
      cover4File: [],
      cover5File: []
    };
  },
  async created() {
    await this.$store.dispatch("getSliderObjects");
    this.sliderItems = this.items;
    this.sliderItems.forEach(item => {
      if (item.sliderNo == 1) {
        this.formData.cover1 = item.coverPhoto;
        this.formData.title1 = item.title;
        this.formData.subtitle1 = item.subtitle;
        this.formData.weblink1 = item.weblink;
      } else if (item.sliderNo == 2) {
        this.formData.cover2 = item.coverPhoto;
        this.formData.title2 = item.title;
        this.formData.subtitle2 = item.subtitle;
        this.formData.weblink2 = item.weblink;
      } else if (item.sliderNo == 3) {
        this.formData.cover3 = item.coverPhoto;
        this.formData.title3 = item.title;
        this.formData.subtitle3 = item.subtitle;
        this.formData.weblink3 = item.weblink;
      } else if (item.sliderNo == 4) {
        this.formData.cover4 = item.coverPhoto;
        this.formData.title4 = item.title;
        this.formData.subtitle4 = item.subtitle;
        this.formData.weblink4 = item.weblink;
      } else if (item.sliderNo == 5) {
        this.formData.cover5 = item.coverPhoto;
        this.formData.title5 = item.title;
        this.formData.subtitle5 = item.subtitle;
        this.formData.weblink5 = item.weblink;
      }
    });

    // this.formData.cover1 = this.sliderItems[0].coverPhoto;
    // this.formData.title1 = this.sliderItems[0].title;
    // this.formData.subtitle1 = this.sliderItems[0].subtitle;
    // this.formData.weblink1 = this.sliderItems[0].weblink;

    // this.formData.cover2 = this.sliderItems[1].coverPhoto;
    // this.formData.title2 = this.sliderItems[1].title;
    // this.formData.subtitle2 = this.sliderItems[1].subtitle;
    // this.formData.weblink2 = this.sliderItems[1].weblink;

    // this.formData.cover3 = this.sliderItems[2].coverPhoto;
    // this.formData.title3 = this.sliderItems[2].title;
    // this.formData.subtitle3 = this.sliderItems[2].subtitle;
    // this.formData.weblink3 = this.sliderItems[2].weblink;

    // this.formData.cover4 = this.sliderItems[3].coverPhoto;
    // this.formData.title4 = this.sliderItems[3].title;
    // this.formData.subtitle4 = this.sliderItems[3].subtitle;
    // this.formData.weblink4 = this.sliderItems[3].weblink;

    // this.formData.cover5 = this.sliderItems[4].coverPhoto;
    // this.formData.title5 = this.sliderItems[4].title;
    // this.formData.subtitle5 = this.sliderItems[4].subtitle;
    // this.formData.weblink5 = this.sliderItems[4].weblink;
  },
  computed: {
    items() {
      return this.$store.state.sliderObjects;
    }
  },
  methods: {
    deleteDropFile(index, file) {
      if (file == 1) {
        this.cover1File.splice(index, 1);
        this.updatecover1 = undefined;
      }
      if (file == 2) {
        this.cover2File.splice(index, 1);
        this.updatecover2 = undefined;
      }
      if (file == 3) {
        this.cover3File.splice(index, 1);
        this.updatecover3 = undefined;
      }
      if (file == 4) {
        this.cover4File.splice(index, 1);
        this.updatecover4 = undefined;
      }
      if (file == 5) {
        this.cover5File.splice(index, 1);
        this.updatecover5 = undefined;
      }
    },

    updateResource(cover, val) {
      if (cover == 1) this.updatecover1 = val;
      if (cover == 2) this.updatecover2 = val;
      if (cover == 3) this.updatecover3 = val;
      if (cover == 4) this.updatecover4 = val;
      if (cover == 5) this.updatecover5 = val;
    },

    async submitForm() {
      this.isSubmitting = true;
      const request = cloneDeep(this.formData);
      const uploadRequest = new FormData();
      if (this.cover1File[0])
        uploadRequest.append("cover1", this.cover1File[0]);
      uploadRequest.append("title1", request.title1);
      uploadRequest.append("subtitle1", request.subtitle1);
      uploadRequest.append("weblink1", request.weblink1);

      if (this.cover2File[0])
        uploadRequest.append("cover2", this.cover2File[0]);
      uploadRequest.append("title2", request.title2);
      uploadRequest.append("subtitle2", request.subtitle2);
      uploadRequest.append("weblink2", request.weblink2);

      if (this.cover3File[0])
        uploadRequest.append("cover3", this.cover3File[0]);
      uploadRequest.append("title3", request.title3);
      uploadRequest.append("subtitle3", request.subtitle3);
      uploadRequest.append("weblink3", request.weblink3);

      if (this.cover4File[0])
        uploadRequest.append("cover4", this.cover4File[0]);
      uploadRequest.append("title4", request.title4);
      uploadRequest.append("subtitle4", request.subtitle4);
      uploadRequest.append("weblink4", request.weblink4);

      if (this.cover5File[0])
        uploadRequest.append("cover5", this.cover5File[0]);
      uploadRequest.append("title5", request.title5);
      uploadRequest.append("subtitle5", request.subtitle5);
      uploadRequest.append("weblink5", request.weblink5);

      await this.$store.dispatch("updateSlider", uploadRequest);
      this.$router.push({ name: "Dashboard" });

      this.isSubmitting = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
